import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {SidebarModule} from 'ng-sidebar';

import {AppRoutes} from './app.routing';
import {AppComponent} from './app.component';
import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {SharedModule} from './shared/shared.module';
import {CommonModule, DatePipe} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BridgeService} from './common/service/bridge.service';
import {JsonHeaderInterceptor} from './common/service/security/json.header.interceptor';
import {ConfigService} from './common/service/config.service';
import {JWTParserService} from './common/service/security/jwt.parser.service';
import {TokenService} from './common/service/token.service';
import {IMenuService} from './layouts/expose-out/service/menu.service';
import {MenuService} from './common/service/impl/menu.service.impl';
import {SubContractorService} from './common/service/sub.contractor.service';
import {UserService} from './common/service/user.service';
import {NumberValidatorService} from './common/service/number.validator.service';
import {DistrictService} from './common/service/district.service';
import {BridgeAuthGuard} from './common/service/security/auth.guard/bridge.auth.guard';
import {SubcontractorAuthGuard} from './common/service/security/auth.guard/subcontractor.auth.guard';
import {UserAuthGuard} from './common/service/security/auth.guard/user.auth.guard';
import {AdminMonitorAuthGuard} from './common/service/security/auth.guard/admin.monitor.auth.guard';
import {ResponseService} from './common/service/response.service';
import {AdminMonitorService} from './common/service/impl/admin-monitor.service.impl';
import {IAdminMonitorService} from './admin-monitor/expose-out/service/admin.monitor.service';
import {RenewBridgeService} from './common/service/renew.bridge.service';
import {UnauthorizedResponseInterceptor} from './common/service/security/unauthorized.response.interceptor';
import {NotfoundResponseInterceptor} from './common/service/security/notfound.response.interceptor';
import {BridgeProgressService} from './common/service/bridge.progress.service';
import {ProfileComponent} from './profile/profile.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PDFExportService} from './common/service/pdf.export.service';
import {ExcelService} from './common/service/excel.service';
import {ReportAuthGuard} from './common/service/security/auth.guard/report.auth.guard';
import {ReportService} from './common/service/report.service';
import {DashboardService} from './common/service/dashboard.service';
import {AnalyticsInterceptor} from './common/service/security/analytics.interceptor';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoadingInterceptor} from './common/service/security/loading.interceptor';
import {initializeKeycloak} from './common/init/keycloak-init.factory';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {AuthGuard} from './common/service/security/auth.guard/auth.guard';
import {LandingAuthGuard} from './common/service/security/auth.guard/landing.auth.guard';
import {AuthService} from './common/service/auth.service';
import {PhaseSelectorAuthGuard} from './common/service/security/auth.guard/phase-selector.auth.guard';
import {AdminService} from './common/service/admin.service';
import {BridgeSpanService} from './common/service/bridge.span.service';
import {AppInfoService} from './common/service/app.info.service';
import {BridgeStageService} from './common/service/bridge.stage.service';
import {BridgeIssueTypeService} from './common/service/bridge.issue.type.service';
import {AppCommonMethods} from './common/app.common,methods';


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes, {}),
    FormsModule,
    HttpClientModule,
    CommonModule,
    SidebarModule.forRoot(),
    NgbModule,
    NgxSpinnerModule.forRoot({type: 'ball-scale-multiple'}),
    KeycloakAngularModule
  ],
  providers: [
    BridgeService,
    RenewBridgeService,
    {provide: IMenuService, useClass: MenuService},
    {provide: IAdminMonitorService, useClass: AdminMonitorService},
    TokenService,
    JWTParserService,
    ConfigService,
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {provide: HTTP_INTERCEPTORS, useClass: AnalyticsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JsonHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedResponseInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: NotfoundResponseInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
    AuthGuard,
    LandingAuthGuard,
    PhaseSelectorAuthGuard,
    BridgeAuthGuard,
    SubcontractorAuthGuard,
    UserAuthGuard,
    ReportAuthGuard,
    AdminMonitorAuthGuard,
    SubContractorService,
    UserService,
    NumberValidatorService,
    DistrictService,
    HttpClientModule,
    ResponseService,
    BridgeProgressService,
    PDFExportService,
    ExcelService,
    DatePipe,
    ReportService,
    DashboardService,
    AdminService,
    BridgeSpanService,
    AppInfoService,
    BridgeStageService,
    BridgeIssueTypeService,
    AppCommonMethods
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
