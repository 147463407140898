import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {JWTParserService} from './security/jwt.parser.service';
import {ConfigService} from './config.service';
import {SECURITY_GROUPS} from '../app.config';
import {Token} from '../../model/security/token.model';
import {AuthService} from './auth.service';

@Injectable()
export class TokenService {

  public static ITEM_TOKEN = 'token';
  public static ITEM_TOKEN_OBJ = 'token-obj';
  public static TENANT = 'tenant';
  public static ERROR_MESSAGE_WRONG_USERNAME_OR_PASSWORD = 'Wrong username or password';

  public static getTokenString(): string {
    return localStorage.getItem(TokenService.ITEM_TOKEN);
  }


  constructor(private _router: Router,
              private _http: HttpClient,
              private jwtParserService: JWTParserService,
              private configService: ConfigService,
              private authService: AuthService) {
  }

  public async setLoginInfo(): Promise<Token> {
    let tokenString = null;
    await this.authService.getToken().then(value => {
      tokenString = value;
    })
    const jwtTokenObject = this.jwtParserService.getJwtTokenObject(tokenString);
    const token: Token = JSON.parse(JSON.stringify(jwtTokenObject));
    localStorage.setItem(TokenService.ITEM_TOKEN, tokenString);
    localStorage.setItem(TokenService.ITEM_TOKEN_OBJ, JSON.stringify(token));
    return token;
  }


  public hasToken(): boolean {
    const token: string = localStorage.getItem(TokenService.ITEM_TOKEN);
    if (token == null || token === '') {
      return false;
    } else {
      return true;
    }
  }

  public getToken(): Token {
    const token: Token = JSON.parse(localStorage.getItem(TokenService.ITEM_TOKEN_OBJ));
    return token;
  }

  public async getUpdatedToken(): Promise<string> {
    if (this.authService.getAuthService().isTokenExpired()) {
      return this.authService.getAuthService().getKeycloakInstance().updateToken(60).then(() =>
        this.authService.getAuthService().getKeycloakInstance().token || '');
    } else {
      return this.authService.getAuthService().getKeycloakInstance().token || '';
    }
  }

  public hasGroup(group: SECURITY_GROUPS): boolean {
    const token: Token = this.getToken();
    const groups = token.groups;
    if (groups == null) {
      return false;
    } else {
      return groups.includes(group);
    }
  }

  public logoutAndClearToken() {
    this.authService.logout();
    this.clearToken();
  }

  public clearToken() {
    localStorage.clear();
    sessionStorage.clear();
  }

  public getTenants(): string[] {
    const token: Token = this.getToken();
    return token.realm_access.roles.filter(role => role.startsWith('TENANT'));
  }

  public getExistingFirstName(): string {
    const token: Token = this.getToken();
    return token.given_name;
  }

  public getExistingLastName(): string {
    const token: Token = this.getToken();
    return token.family_name;
  }

  public getExistingName(): string {
    const token: Token = this.getToken();
    return token.name;
  }

  public getExistingEmail(): string {
    const token: Token = this.getToken();
    return token.email;
  }

  public getExistingUsername(): string {
    const token: Token = this.getToken();
    return token.preferred_username;
  }

  public getExistingUserId(): string {
    const token: Token = this.getToken();
    return token.sub;
  }

  public setUpdatedName(updatedName) {
    const token: Token = this.getToken();
    token.name = updatedName;
    localStorage.setItem(TokenService.ITEM_TOKEN_OBJ, JSON.stringify(token));
  }

}


