import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BridgeStageAPI} from '../backend.api.config';
import {BridgeStageModel} from '../../model/bridge.stage.model';

@Injectable({
  providedIn: 'root'
})
export class BridgeStageService {
  constructor(private _http: HttpClient) {
  }

  public listBridgeStage(): Observable<BridgeStageModel[]> {
    return this._http.get<BridgeStageModel[]>(BridgeStageAPI.LIST);
  }

  public saveBridgeStage(bridgeStage: BridgeStageModel) {
    return this._http.post(BridgeStageAPI.SAVE, bridgeStage);
  }

  public deleteBridgeStage(bridgeStageId: number) {
    return this._http.delete(BridgeStageAPI.DELETE + '/' + bridgeStageId);
  }
}
