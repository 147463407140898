import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {TokenService} from '../../token.service';
import {SECURITY_GROUPS} from '../../../app.config';
import {FinalUrls} from './final.urls';

@Injectable()
export class BridgeAuthGuard implements CanActivate {

  constructor(private _router: Router, private tokenService: TokenService) {
  }

  canActivate(): boolean {
    let url: string = this._router.getCurrentNavigation().finalUrl.toString();
    const urlParts = url.split('/');
    if (urlParts.length === 4) {
      urlParts.pop();
    }
    url = urlParts.join('/');
    let status = false;

    switch (url) {
      case FinalUrls.BRIDGE_NEW:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_EXTERNAL_USER) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SECONDARY_USER)) {
          status = true;
        }
        break;

      case FinalUrls.BRIDGE_RENEW:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_EXTERNAL_USER)
          || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN)) {
          status = true;
        }
        break;
      case FinalUrls.BRIDGE_RENEW_LIST:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_EXTERNAL_USER)
          || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_ADMIN)) {
          status = true;
        }
        break;

      case FinalUrls.BRIDGE_PROGRESS:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_ADMIN)
          || this.tokenService.hasGroup(SECURITY_GROUPS.G_SECONDARY_USER) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN)) {
          status = true;
        }
        break;
      case FinalUrls.BRIDGE_PROGRESS_NOT_REPORTED:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_ADMIN)
          || this.tokenService.hasGroup(SECURITY_GROUPS.G_SECONDARY_USER) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN)) {
          status = true;
        }
        break;
      case FinalUrls.BRIDGE_ISSUE:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_ADMIN)
          || this.tokenService.hasGroup(SECURITY_GROUPS.G_SECONDARY_USER) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN)) {
          status = true;
        }
        break;

      case FinalUrls.BRIDGE_LIST:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_ADMIN)
          || this.tokenService.hasGroup(SECURITY_GROUPS.G_SECONDARY_USER) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN)
          || this.tokenService.hasGroup(SECURITY_GROUPS.G_EXTERNAL_USER)) {
          status = true;
        }
        break;
      case FinalUrls.SPAN_MASTER:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_EXTERNAL_USER)
          || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN)) {
          status = true;
        }
        break;
    }

    if (!status) {
      FinalUrls.navigateToDashboard(this._router, url);
    }

    return status;
  }

}
