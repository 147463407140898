import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberValidatorService {

  constructor() {
  }

  public numbersOnly(event: any) {
    const pattern = /[0-9\+.\+0-9]/;
    const inputChar = String.fromCharCode(event.keyCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) { // 8 - Backspace
      event.preventDefault();
    }
  }

  public isZero(numericValue: String): boolean {
    return numericValue === '' || numericValue === '0';
  }
}
