import {filter} from 'rxjs/operators';
import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {Subscription} from 'rxjs';
import {IMenuService} from '../expose-out/service/menu.service';
import {Menu} from '../expose-out/model/menu';
import {environment} from '../../../environments/environment';
import {JWTParserService} from '../../common/service/security/jwt.parser.service';
import {TokenService} from '../../common/service/token.service';
import Swal from 'sweetalert2';
import {ProfileComponent} from '../../profile/profile.component';
import {NAVIGATION} from '../../common/app.config';
import {AppInfoModel} from '../../model/app.info';

export interface Options {
  heading?: string;
  removeFooter?: boolean;
  mapHeader?: boolean;
}

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  appEnvDisplayName: string = environment.APP_ENV_DISPLAY_NAME;
  appVersion: string = environment.APP_VERSION;
  appInfo: AppInfoModel;
  existName: string;
  currentTenant: string;
  menuItems: Menu[] = [];
  currentLang = 'en';
  options: Options;
  theme = 'light';
  showSettings = false;
  isDocked = false;
  isBoxed = false;
  isOpened = true;
  mode = 'push';
  _mode = this.mode;
  _autoCollapseWidth = 991;
  width = window.innerWidth;
  @ViewChild('sidebar', {static: true}) sidebar;
  private _router: Subscription;

  constructor(
    private menuService: IMenuService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private titleService: Title, private jWTParserService: JWTParserService, private tokenService: TokenService) {
  }

  ngOnInit(): void {
    this.existName = this.tokenService.getExistingName();
    this.getCurrentTenant();
    this.getAppInfo();

    if (this.isOver()) {
      this._mode = 'over';
      this.isOpened = false;
    }

    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      // Scroll to top on view load
      document.querySelector('.main-content').scrollTop = 0;

      if (this.isOver() || event.url === '/maps/fullscreen') {
        this.isOpened = false;
      }

      this.route.children.forEach((route: ActivatedRoute) => {
        let activeRoute: ActivatedRoute = route;
        while (activeRoute.firstChild) {
          activeRoute = activeRoute.firstChild;
        }
        this.options = activeRoute.snapshot.data;
      });

      if (this.options.hasOwnProperty('heading')) {
        this.setTitle(this.options.heading);
      }
    });

    this.menuItems = this.menuService.getMenuDataForTheUserContext();
  }

  signOut() {
    Swal.fire({
      position: 'center',
      type: 'warning',
      title: 'Are you sure ?',
      text: 'you want to log out?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#dd5964',
      confirmButtonText: 'Log out',
      showCloseButton: false,
      focusCancel: true,
    }).then(yes => {
      if (yes.value) {
        this.menuService.signOut();
        this.router.navigate([this.menuService.afterSignOutRedirectURL()]);
      }
    });
  }

  ngOnDestroy() {
    this._router.unsubscribe();
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(this.appInfo.title + ' | ' + newTitle);
  }

  toogleSidebar(): void {
    if (this._mode !== 'dock') {
      this.isOpened = !this.isOpened;
    }
  }

  isOver(): boolean {
    return window.matchMedia(`(max-width: 991px)`).matches;
  }

  openSearch(search) {
    this.modalService.open(search, {windowClass: 'search', backdrop: false});
  }

  openUserProfile() {
    this.modalService.open(ProfileComponent, {
      size: 'lg'
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.width === event.target.innerWidth) {
      return false;
    }
    if (this.isOver()) {
      this._mode = 'over';
      this.isOpened = false;
    } else {
      this._mode = this.mode;
      this.isOpened = true;
    }
    this.width = event.target.innerWidth;
  }

  getCurrentTenant() {
    this.currentTenant = sessionStorage.getItem(TokenService.TENANT);
  }

  switchPhase() {
    if (this.tokenService.getTenants().length !== 1) {
      Swal.fire({
        position: 'center',
        type: 'warning',
        title: 'Are you sure?',
        text: 'Do you want to Switch Phase?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#0075a1',
        confirmButtonText: 'Switch',
        showCloseButton: false,
        focusCancel: true,
      }).then(yes => {
          if (yes.value) {
            this.router.navigate([NAVIGATION.PHASE_SELECTOR]);
          }
        }
      );
    }
  }

  getAppInfo() {
    this.appInfo = JSON.parse(sessionStorage.getItem('appInfo'));
    this.titleService.setTitle(this.appInfo.title);
  }
}
