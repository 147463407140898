import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {TokenService} from '../../token.service';
import {FinalUrls} from './final.urls';
import {SECURITY_GROUPS} from '../../../app.config';

@Injectable()
export class AdminMonitorAuthGuard implements CanActivate {

  constructor(private _router: Router, private tokenService: TokenService) {
  }

  canActivate(): boolean {
    const url: string = this._router.getCurrentNavigation().finalUrl.toString();
    let status = false;

    if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN)) {
      status = true;
    }

    if (!status) {
      FinalUrls.navigateToDashboard(this._router, url);
    }

    return status;
  }
}
