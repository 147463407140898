import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {TokenService} from '../../token.service';
import {FinalUrls} from './final.urls';
import {SECURITY_GROUPS} from '../../../app.config';

@Injectable()
export class ReportAuthGuard implements CanActivate {

  constructor(private _router: Router, private tokenService: TokenService) {
  }

  canActivate(): boolean {
    const url: string = this._router.getCurrentNavigation().finalUrl.toString();
    let status = false;

    switch (url) {
      case FinalUrls.PROGRESS_REPORT:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN)) {
          status = true;
        }
        break;

      case FinalUrls.BRIDGE_REPORT:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN) ||
          this.tokenService.hasGroup(SECURITY_GROUPS.G_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SECONDARY_USER)
          || this.tokenService.hasGroup(SECURITY_GROUPS.G_EXTERNAL_USER)) {
          status = true;
        }
        break;
      case FinalUrls.PROJECT_DELIVERABLE_REPORT:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN)) {
          status = true;
        }
        break;
      case FinalUrls.MONTHLY_REPORT:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN)
        || this.tokenService.hasGroup(SECURITY_GROUPS.G_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_EXTERNAL_USER)) {
          status = true;
        }
        break;
    }

    if (!status) {
      FinalUrls.navigateToDashboard(this._router, url);
    }

    return status;
  }

}
