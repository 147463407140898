import {Routes} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {NAVIGATION} from './common/app.config';
import {BridgeAuthGuard} from './common/service/security/auth.guard/bridge.auth.guard';
import {SubcontractorAuthGuard} from './common/service/security/auth.guard/subcontractor.auth.guard';
import {UserAuthGuard} from './common/service/security/auth.guard/user.auth.guard';
import {AdminMonitorAuthGuard} from './common/service/security/auth.guard/admin.monitor.auth.guard';
import {ReportAuthGuard} from './common/service/security/auth.guard/report.auth.guard';
import {AuthGuard} from './common/service/security/auth.guard/auth.guard';
import {LandingAuthGuard} from './common/service/security/auth.guard/landing.auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
      canActivate: [LandingAuthGuard]
    }, {
      path: NAVIGATION.PHASE_SELECTOR,
      loadChildren: () => import('././phase-selector/phase-selector.module').then(m => m.PhaseSelectorModule),
      canActivate: [AuthGuard]
    }, {
      path: NAVIGATION.ERROR,
      loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
    }]
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      canActivate: [AuthGuard]
    }, {
      path: NAVIGATION.DASHBOARD,
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      canActivate: [AuthGuard]
    }, {
      path: NAVIGATION.BRIDGE,
      loadChildren: () => import('./bridge/bridge.module').then(m => m.BridgeModule),
      canActivate: [AuthGuard, BridgeAuthGuard]
    }, {
      path: NAVIGATION.SUBCONTRACTOR,
      loadChildren: () => import('./subcontractor/subcontractor.module').then(m => m.SubcontractorModule),
      canActivate: [AuthGuard, SubcontractorAuthGuard]
    }, {
      path: NAVIGATION.USER,
      loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      canActivate: [AuthGuard, UserAuthGuard]
    }, {
      path: NAVIGATION.REPORT,
      loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
      canActivate: [AuthGuard, ReportAuthGuard]
    }, {
      path: NAVIGATION.ADMIN_MONITOR,
      loadChildren: () => import('./admin-monitor/admin-monitor.module').then(m => m.AdminMonitorModule),
      canActivate: [AuthGuard, AdminMonitorAuthGuard]
    }, {
      path: NAVIGATION.ABOUT_US,
      loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule)

    }, {
      path: NAVIGATION.ADMIN_PANEL,
      loadChildren: () => import('./admin-panel/admin-panel.module').then(m => m.AdminPanelModule)

    }, {
      path: NAVIGATION.APP_SETTINGS,
      loadChildren: () => import('./app-settings/app-settings.module').then(m => m.AppSettingsModule)
    }, {
      path: NAVIGATION.NOT_CONFIGURED,
      redirectTo: 'error/404'
    }]
  }];

