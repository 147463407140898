import {environment} from '../../environments/environment';


export class AppConfig {

  public static BACKEND_API_BASE_URL = environment.API_BASE_URL;
  public static GOOGLE_MAPS_API_KEY = 'AIzaSyB4w0Jd1rAVU3XjQSGPGRPA2vYvwX9KL1E';

  public static BASE64_TAG_JPEG = 'data:image/jpeg;base64,';
}

export enum NAVIGATION {
  HOME = 'login',
  ERROR = 'error',
  LANDING = 'landing',
  DASHBOARD = 'dashboard',
  PHASE_SELECTOR = 'phase-selector',
  BRIDGE = 'bridge',
  SUBCONTRACTOR = 'subcontractor',
  USER = 'user',
  REPORT = 'report',
  ADMIN_MONITOR = 'admin-monitor',
  ABOUT_US = 'about-us',
  ADMIN_PANEL = 'admin-panel',
  APP_SETTINGS = 'app-settings',
  NOT_CONFIGURED = '**'
}

export enum SECURITY_GROUPS {
  G_ADMIN = 'G_ADMIN',
  G_SUPER_ADMIN = 'G_SUPER_ADMIN',
  G_SECONDARY_USER = 'G_SECONDARY_USER',
  G_EXTERNAL_USER = 'G_EXTERNAL_USER',
  G_SYSENSOR_ADMIN = 'G_SYSENSOR_ADMIN',
  G_MOBILE_USER = 'G_MOBILE_USER'
}
