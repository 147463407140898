import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {TokenService} from '../token.service';

@Injectable()
export class AnalyticsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tenantId = sessionStorage.getItem(TokenService.TENANT);
    const request = req.clone({
      headers: req.headers.set('X-Source', 'Janson-web-app').set('X-Source-Version', environment.APP_VERSION).set('X-TenantID', tenantId)
    });
    return next.handle(request);
  }
}
