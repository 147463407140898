import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {environment} from '../../../../../environments/environment';
import {TokenService} from '../../token.service';
import {AuthService} from '../../auth.service';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private tokenService: TokenService,
    private authService: AuthService
  ) {
    super(router, authService.getAuthService());
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    if (!this.authenticated) {
      this.tokenService.clearToken();
      await this.authService.login(environment.KEYCLOAK_CONFIG.redirect_uri + state.url)
    }

    if (!this.tokenService.hasToken()) {
      await this.tokenService.setLoginInfo();
    }
    return this.authenticated;
  }
}
